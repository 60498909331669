import React, { Suspense } from 'react';
import LoaderComponent from '../components/elements/LoaderComponent';
import { Route } from 'react-router-dom';
import Users from '../pages/Users';
import dayjs from 'dayjs';
import { IoIosStats } from "react-icons/io";

import { ExtrasMenuIcon,
         CoursesMenuIcon,
         BenefitsMenu,
         IconInventoryIcon,
         LogoutMenuIcon,
         MetricsMenuIcon,
         NotificationMenuIcon,
         PortfolioMenuIcon,
         UsersMenuIcon,
         ImportMenuIcon,
         WallMenuIcon,
         StoreIcon,
         PostMenuIcon,
         SurveyMenu,
         StatsMenu} from './icons';

import { DateTime } from "luxon";

export const getMenuIcon = (item) => {
    let icon = null;
    switch (item.id) {
        case "1":
            icon = <UsersMenuIcon />;
            break;
        case "2":
            icon = <PostMenuIcon />;
            break;
        case "3":
            icon = <StoreIcon />;
            break;
        case "4":
            icon = <CoursesMenuIcon />;
            break;
        case "5":
            icon = <BenefitsMenu />;
            break;
        case "6":
            icon = <IconInventoryIcon />;
            break;
        case "7":
            icon = <SurveyMenu />;
            break;
        case "8":
            icon = <StatsMenu />;
            break;
        case "999":
            icon = <ImportMenuIcon />;
            break;
        default:
            //return null;
            break;
    }
    return icon;
}

export const resolveUrl = (item_id) => {
    let slug = "";
    switch (item_id) {
        case "1":
            slug = "users";
            break;
        case "2":
            slug = "posts";
            break;
        case "3":
            slug = "stores";
            break;
        case "4":
            slug = "courses";
            break;
        case "5":
            slug = "benefits";
            break;
        case "6":
            slug = "products";
            break;
        case "7":
            slug = "survey";
            break;
        case "8":
            slug = "reporting";
            break; 
        case "999":
            slug = "import";
            break;
        default:
            //return null;
            break;
    }
    return slug;
    //return encodeURI(name.replace(/\s/g, "-")).toLowerCase();
}

const loadComponent = (name) => {
    const Component = React.lazy(() =>
        import(`../pages/${name}`)
    );
    return Component;
}

export const resolveComponentName = (item) => {
    
    switch (item.id) {
        case "111":
            const UsersComponent = loadComponent("Users");
            const UsersEditComponent = loadComponent("Users/Edit");
            const UsersViewComponent = loadComponent("Users/View");
            const UsersCreateComponent = loadComponent("Users/Create");
            
            return(
                    <React.Fragment>
                        <Route 
                            path={"/users"}//${resolveUrl(item.label)}
                            element={
                                <Suspense fallback={<LoaderComponent />}>
                                    <UsersComponent />
                                </Suspense>} 
                            exact 
                        />
                        <Route 
                            path={"/users/create"}//${resolveUrl(item.label)}
                            element={
                                <Suspense fallback={<LoaderComponent />}>
                                    <UsersCreateComponent />
                                </Suspense>} 
                            exact 
                        />
                        <Route 
                            //path={`/${resolveUrl(item.label)}/:slug/:id`}
                            path={"/users/edit/:id"}
                            element={
                                <Suspense fallback={<LoaderComponent />}>
                                    <UsersEditComponent />
                                </Suspense>} 
                            exact 
                        />
                        <Route 
                            path={"/users/view/:id"}
                            element={
                                <Suspense fallback={<LoaderComponent />}>
                                    <UsersViewComponent />
                                </Suspense>} 
                            exact 
                        />
                    </React.Fragment>
            )
            break;
        case "2":
            const PostsComponent = loadComponent("Posts");
            const PostsEditComponent = loadComponent("Posts/Edit");
            const PostsViewComponent = loadComponent("Posts/View");
            const PostsCreateComponent = loadComponent("Posts/Create");
            return(

                <React.Fragment>
                    <Route 
                        path={"/posts"}
                        element={
                            <Suspense fallback={<LoaderComponent />}>
                                <PostsComponent />
                            </Suspense>} 
                        exact 
                    />
                    <Route 
                        path={"/posts/:id_post/edit"}
                        element={
                            <Suspense fallback={<LoaderComponent />}>
                                <PostsEditComponent />
                            </Suspense>} 
                        exact 
                    />
                    <Route 
                        path={"/posts/:id_post/view"}
                        element={
                            <Suspense fallback={<LoaderComponent />}>
                                <PostsViewComponent />
                            </Suspense>} 
                        exact 
                    />
                    <Route 
                        path={"/posts/create"}
                        element={
                            <Suspense fallback={<LoaderComponent />}>
                                <PostsCreateComponent />
                            </Suspense>} 
                        exact 
                    />
                </React.Fragment>
            )
            break;
        /*
        case "3":
            const StoresComponent = loadComponent("Stores");
            const StoresEditComponent = loadComponent("Stores/Edit");
            const StoresViewComponent = loadComponent("Stores/View");
            const StoresTestComponent = loadComponent("Stores/Test");
            const StoresCreateComponent = loadComponent("Stores/Create");
            return(

                <React.Fragment>
                    <Route 
                        path={"/stores"}
                        element={
                            <Suspense fallback={<LoaderComponent />}>
                                <StoresComponent />
                            </Suspense>} 
                        exact 
                    />
                    <Route 
                        path={"/stores/create"}
                        element={
                            <Suspense fallback={<LoaderComponent />}>
                                <StoresCreateComponent />
                            </Suspense>} 
                        exact 
                    />
                    <Route 
                        //path={`/${resolveUrl(item.label)}/:slug/:id`}
                        path={"/stores/:id_store/edit"}
                        element={
                            <Suspense fallback={<LoaderComponent />}>
                                <StoresEditComponent />
                            </Suspense>} 
                        exact 
                    />
                    <Route 
                        path={"/stores/:id_store/view"}
                        element={
                            <Suspense fallback={<LoaderComponent />}>
                                <StoresViewComponent />
                            </Suspense>} 
                        exact 
                    />
                    <Route 
                        path={"/stores/view/test"}
                        element={
                            <Suspense fallback={<LoaderComponent />}>
                                <StoresTestComponent />
                            </Suspense>} 
                        exact 
                    />
                </React.Fragment>
            )
            break;
        */
        case "3333":
            const WallComponent = loadComponent("Wall");
            return(
                <Route path={`/wall`} element={<Users />} exact />
            )
            break;
        case "4":
            const CoursesComponent = loadComponent("Courses");
            const CoursesCreateComponent = loadComponent("Courses/Create");
            const CoursesEditComponent = loadComponent("Courses/Edit");
            const CoursesViewComponent = loadComponent("Courses/View");
            

            const CoursesModulesComponent = loadComponent("Courses/Modules");
            const CoursesModulesCreateComponent = loadComponent("Courses/Modules/Create");
            const CoursesModulesEditComponent = loadComponent("Courses/Modules/Edit");
            const CoursesModulesViewComponent = loadComponent("Courses/Modules/View");
            
            return(
                    <React.Fragment>
                        <Route 
                            path={"/courses"}
                            element={
                                <Suspense fallback={<LoaderComponent />}>
                                    <CoursesComponent />
                                </Suspense>} 
                            exact 
                        />
                        <Route 
                            path={"/courses/create"}//${resolveUrl(item.label)}
                            element={
                                <Suspense fallback={<LoaderComponent />}>
                                    <CoursesCreateComponent />
                                </Suspense>} 
                            exact 
                        />

                        <Route 
                            path={"/course/:id_course/edit"}
                            element={
                                <Suspense fallback={<LoaderComponent />}>
                                    <CoursesEditComponent />
                                </Suspense>} 
                            exact 
                        />
                        <Route 
                            path={"/course/:id_course/view"}
                            element={
                                <Suspense fallback={<LoaderComponent />}>
                                    <CoursesViewComponent />
                                </Suspense>} 
                            exact 
                        />
                        <Route 
                            path={"/course/:id/modules"}
                            element={
                                <Suspense fallback={<LoaderComponent />}>
                                    <CoursesModulesComponent />
                                </Suspense>} 
                            exact 
                        />
                        <Route 
                            path={"/course/:id_course/modules/create"}
                            element={
                                <Suspense fallback={<LoaderComponent />}>
                                    <CoursesModulesCreateComponent />
                                </Suspense>} 
                            exact 
                        />
                        <Route 
                            path={"/course/:id_course/modules/:id_module/edit"}
                            element={
                                <Suspense fallback={<LoaderComponent />}>
                                    <CoursesModulesEditComponent />
                                </Suspense>} 
                            exact 
                        />
                         <Route 
                            path={"/course/:id_course/modules/:id_module/view"}
                            element={
                                <Suspense fallback={<LoaderComponent />}>
                                    <CoursesModulesViewComponent />
                                </Suspense>} 
                            exact 
                        />
                    </React.Fragment>
                    
            )
            break;
        case "5":
            const BenefitsComponent = loadComponent("Benefits");
            const BenefitsEditComponent = loadComponent("Benefits/Edit");
            const BenefitsViewComponent = loadComponent("Benefits/View");
            const BenefitsCreateComponent = loadComponent("Benefits/Create");

            const RedeemBenefitsComponent = loadComponent("Benefits/Redeem");
            //const RedeemBenefitsEditComponent = loadComponent("Benefits/Redeem/Edit");
            //const RedeemBenefitsViewComponent = loadComponent("Benefits/Redeem/View");
            //const RedeemBenefitsCreateComponent = loadComponent("Benefits/Redeem/Create");
            return(

                <React.Fragment>
                    <Route 
                        path={"/benefits"}
                        element={
                            <Suspense fallback={<LoaderComponent />}>
                                <BenefitsComponent />
                            </Suspense>} 
                        exact 
                    />
                    <Route 
                        path={"/benefits/create"}
                        element={
                            <Suspense fallback={<LoaderComponent />}>
                                <BenefitsCreateComponent />
                            </Suspense>} 
                        exact 
                    />
                    <Route 
                        path={"/benefits/:id_benefit/edit"}
                        element={
                            <Suspense fallback={<LoaderComponent />}>
                                <BenefitsEditComponent />
                            </Suspense>} 
                        exact 
                    />
                    <Route 
                        path={"/benefits/:id_benefit/view"}
                        element={
                            <Suspense fallback={<LoaderComponent />}>
                                <BenefitsViewComponent />
                            </Suspense>} 
                        exact 
                    />
                    <Route 
                        path={"/benefits/redeem"}
                        element={
                            <Suspense fallback={<LoaderComponent />}>
                                <RedeemBenefitsComponent />
                            </Suspense>} 
                        exact 
                    />
                </React.Fragment>
            )
            break;
        case "6":
            const ProductsComponent = loadComponent("Products");
            const ProductsCreateComponent = loadComponent("Products/Create");
            const ProductsEditComponent = loadComponent("Products/Edit");
            const ProductsViewComponent = loadComponent("Products/View");
            const ProductsCategoryComponent = loadComponent("Products/Categories");
            return(

                <React.Fragment>
                    <Route 
                        path={"/products"}
                        element={
                            <Suspense fallback={<LoaderComponent />}>
                                <ProductsComponent />
                            </Suspense>} 
                        exact 
                    />
                    <Route 
                        path={"/products/categories"}
                        element={
                            <Suspense fallback={<LoaderComponent />}>
                                <ProductsCategoryComponent />
                            </Suspense>} 
                        exact 
                    />
                    <Route 
                        path={"/products/create"}
                        element={
                            <Suspense fallback={<LoaderComponent />}>
                                <ProductsCreateComponent />
                            </Suspense>} 
                        exact 
                    />
                    <Route 
                        path={"/products/:id_product/edit"}
                        element={
                            <Suspense fallback={<LoaderComponent />}>
                                <ProductsEditComponent />
                            </Suspense>} 
                        exact 
                    />
                    <Route 
                        path={"/products/:id_product/view"}
                        element={
                            <Suspense fallback={<LoaderComponent />}>
                                <ProductsViewComponent />
                            </Suspense>} 
                        exact 
                    />
                </React.Fragment>
            )
            break;
        case "7":
            const SurveyComponent = loadComponent("Survey");
            const SurveyCreateComponent = loadComponent("Survey/Create");
            const SurveyEditComponent = loadComponent("Survey/Edit");
            return(

                <React.Fragment>
                    <Route 
                        path={"/survey"}
                        element={
                            <Suspense fallback={<LoaderComponent />}>
                                <SurveyComponent />
                            </Suspense>} 
                        exact 
                    />
                    <Route 
                        path={"/survey/create"}
                        element={
                            <Suspense fallback={<LoaderComponent />}>
                                <SurveyCreateComponent />
                            </Suspense>} 
                        exact 
                    />
                    <Route 
                        path={"/survey/:id_survey/edit"}
                        element={
                            <Suspense fallback={<LoaderComponent />}>
                                <SurveyEditComponent />
                            </Suspense>} 
                        exact 
                    />
                </React.Fragment>
            )
            break;
        case "8":
            const BiComponent = loadComponent("Bi");

            return(
                <React.Fragment>
                    <Route 
                        path={"/reporting"}
                        element={
                            <Suspense fallback={<LoaderComponent />}>
                                <BiComponent />
                            </Suspense>} 
                        exact 
                    />
                </React.Fragment>
            )
            break;
        case "999":
            return(
                <React.Fragment>
                    <Route 
                        path={"https://v2-importers.1onesolutions.com/?token=fwefwefewf"}
                    />
                </React.Fragment>
            )
            break;
        default:
            return null;
            break;
    }
    
}

export const extractFileName = (url) => {
    try {
        const urlObj = new URL(url);
        const pathname = urlObj.pathname;
        const pathSegments = pathname.split('/');
        const fileName = pathSegments[pathSegments.length - 1];
        return decodeURIComponent(fileName);
    } catch (error) {
        return "";//null;
    }
}

export const shortenFileName = (fileName, size) => {
    
    fileName = fileName.split("/");
    fileName = fileName[fileName.length-1].split(".");

    const length = fileName[0].length;
    const halfLength = Math.ceil(length / 2);
    const firstHalf = fileName[0].substring(0, halfLength);
    const secondHalf = fileName[0].substring(length - halfLength, length);

    return `${firstHalf.substring(0, size)}...${secondHalf.substring(secondHalf.length - size)}.${fileName[fileName.length-1]/*fileName[1]*/}`;
    
}

export const isOlderThan = (date) => {

    const now = DateTime.now().toFormat('yyyy-MM-dd HH:mm');
    const _date = DateTime.fromISO(date).setZone("UTC").toFormat('yyyy-MM-dd HH:mm');

    if(dayjs(now).isAfter(dayjs(_date))){
        return true;
    }else{
        return false;
    }
    
}

export const colorizeCourseQualification = (value) => {

    if(value >= 0 && value < 40){
        return "qualy_red";
    }
    if(value >= 40 && value < 60){
        return "qualy_yellow";
    }
    if(value >= 60 && value < 90){
        return "qualy_green";
    }
    if(value >= 90 && value <= 100){
        return "qualy_blue";
    }

}

export const rescalingElement = (originalWidth, originalHeight, targetWidth, targetHeight) => {
    
    let newWidth, newHeight;
    
    if (originalWidth > originalHeight) {
        newWidth = targetWidth;
        newHeight = (originalHeight * targetWidth) / originalWidth;
    } else if (originalHeight > originalWidth) {
        newHeight = targetHeight;
        newWidth = (originalWidth * targetHeight) / originalHeight;
    } else {
        newWidth = targetWidth;
        newHeight = targetWidth;//targetHeight;
    }
    
    return {
        width: newWidth,
        height: newHeight
    };

}

export const getNameLanguage = (languages, language) => {
    const name = languages.filter(lang => lang.language_identifier === language);
    if(name){
        return name[0].name;
    }
    return "";
}

export const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

export const validateDNI = (dni) => {
    const re = /^[A-Za-z0-9]*$/;
    return re.test(dni);
}

export const validateParamName = (text) => {
    const re = /^[A-Za-z0-9\_]*$/;
    return re.test(text);
}

export const validateUniqueID = (unique_id) => {
    //const re = /^[A-Za-z0-9\@\.\#\=]*$/;
    //const re = /^[a-zA-Z0-9_@.]+$/;///^[A-Za-z0-9\_@]*$/;
    const re = /^[a-zA-Z0-9_@.-]+$/;
    return re.test(unique_id);
}

export const validateNoTagsHTML = (text) => {
    const re = /<\/?[a-z][^>]*>/ig;
    return re.test(text);
}

export const validateLatLng = (text) => {
    const re = /^[0-9.-]+$/ig;
    return re.test(text);
}

export const validateOnlyIntegers = (text) => {
    const re = /^[0-9]+$/ig;
    return re.test(text);
}

export const validateKeywords = (text) => {
    const re = /^[^áéíóúñÁÉÍÓÚÑ¿?¡!]*$/;
    return re.test(text);
}



export const aviableLangs = (langs, lang) => {
    let response = false;
    for(let i = 0; i < langs.length; i++) {
        if(langs[i].prefix == lang){
            response = true;
        }
    }
    return response;
}

export const retrieveAreaDescription = (data, _id) => {
    const exist = data.filter(d => d._id == _id);
    if(exist){
        return exist[0].name;
    }else{
        return null;
    }

}

export const getCitiesByCountry = (data, country_id) => {
    
    const country_selected = data.filter(country => country._id == country_id);
    if(country_selected.length){
        return country_selected[0].citys;
    }
    return null;
}

export const getTypeAssesment = (assessment_type, id) => {
    const assessment_type_selected = assessment_type.filter(type => type._id == id);
    if(assessment_type_selected.length){
        return assessment_type_selected[0].title;
    }
    return null;
    
}

export const getTypeAssesmentLabel = (assessment_type, id) => {
    
    const assessment_type_selected = assessment_type.filter(type => type._id == id);
    if(assessment_type_selected.length){
        return assessment_type_selected[0].label;
    }
    return null;
    
}
export const getQuestionTypeLabel = (questions_type, id) => {
    const question_type_selected = questions_type.filter(type => type._id == id);
    if(question_type_selected.length){
        return question_type_selected[0].label;
    }
    return null;
}
export const getQuestionTypeTitle = (questions_type, id) => {
    const question_type_selected = questions_type.filter(type => type._id == id);
    if(question_type_selected.length){
        return question_type_selected[0].title;
    }
    return null;
}
export const getQuestionTypeId = (questions_type, title) => {
    const question_type_selected = questions_type.filter(type => type.title == title);
    if(question_type_selected.length){
        return question_type_selected[0]._id;
    }
    return null;
    
}
export const getNameFromDataById = (data, id) => {
    const result = data.filter(d => d._id == id);
    if(result.length){
        return result[0].name;
    }
    return null;
    
}
export const getLabelName = (data, id) => {
    const result = data.filter(d => d._id == id);
    if(result.length){
        return result[0].name;
    }
    return null;
}
export const ifLabelExist = (data, label) => {
    const result = data.filter(d => d.name == label);
    if(result.length){
        return true;
    }
    return false;
}
export const replaceSpecialCharacters = (textoOriginal) => {
    const palabrasAcentuadas = {
      'á': 'a', 'é': 'e', 'í': 'i', 'ó': 'o', 'ú': 'u', 'ü': 'u',
      'Á': 'A', 'É': 'E', 'Í': 'I', 'Ó': 'O', 'Ú': 'U', 'Ü': 'u',
      'ñ': 'n', 'Ñ': 'N'
    };
  
    const caracteresEspeciales = /[^a-zA-Z0-9\s]/g;
  
    // Reemplazar palabras acentuadas y la letra "ñ"
    let textoSinAcentosYN = textoOriginal;
    for (const acento in palabrasAcentuadas) {
      const expresionRegular = new RegExp(acento, 'g');
      textoSinAcentosYN = textoSinAcentosYN.replace(expresionRegular, palabrasAcentuadas[acento]);
    }
  
    // Eliminar caracteres especiales
    return textoSinAcentosYN.replace(caracteresEspeciales, '');
  }

export const haveSubcategories = (value, ecommerceCategories) => {
    for (const item of ecommerceCategories) {
        if (item._id === value) {
          if (item.categories) {
            return item;//item.categories;
          }
        } else if (item.categories) {
          const result = haveSubcategories(value, item.categories);
          if (result) {
            return result;
          }
        }
      }
      return null;
}

export const isJSON = (str) => {
    try {
        JSON.parse(str);
        return true;
    } catch (e) {
        return false;
    }
}

export const isBase64 = (str) => {

    // Verificar si el string es válido
  if (typeof str !== 'string' || str === '') {
    return false;
  }

  // Dividir el string utilizando el separador
  var parts = str.split(',');

  // Verificar si hay dos partes (tipo y datos)
  if (parts.length !== 2) {
    return false;
  }

  // Verificar si la segunda parte es una cadena Base64 válida
  var base64Part = parts[1].trim();
  var validBase64Chars = /^[A-Za-z0-9+/=]+$/;
  if (!validBase64Chars.test(base64Part)) {
    return false;
  }

  // Verificar si la longitud de la cadena Base64 es múltiplo de 4
  if (base64Part.length % 4 !== 0) {
    return false;
  }

  return true;
}

export const arrayCategories = (objectCategory, result = []) => {
    if(objectCategory._id){
        result.push(objectCategory._id);
    }
    
    if (objectCategory.categories) {
        arrayCategories(objectCategory.categories, result);
    }
    
    return result;

}

export const retrieveFileType = (path) => {
    if(path){
        const parts = {};
        const match = path.match(/^(.*\/)?([^.]+)(\..+)?$/);

        if (match) {
            parts.directory = match[1] || '';
            parts.filename = match[2] || '';
            parts.extension = match[3] || '';
        }

        if(parts.extension === ".pdf" || parts.extension === ".xlx" || parts.extension === ".xlxs" || parts.extension === ".doc" || parts.extension === ".docx" || parts.extension === ".ppt" || parts.extension === ".pptx"){
            return "Archives";
        }
    }else{
        return "";
    }
}
export const dataObjectVouchers = (data) => {
    return {
        
        cover_image: { value: data ? data.cover_image : "", 
                       cropped_image: "",
                       upload_route: "",
                       required: true, 
                       disabled: false, 
                       ref: "", 
                       label: "", 
                       placeholder: "",
                       help: "",
                       error: false },
                       
        title: { value: data ? data.title : "", 
                 required: true, 
                 disabled: false, 
                 ref: "title", 
                 label: "Titulo del voucher", 
                 placeholder: "Ingresar titulo",
                 help: "",
                 error: false },
        
        description_long: { value: data ? data.description_long : "", 
                            required: true, 
                            disabled: false, 
                            ref: "description_long", 
                            label: "Descripcion del beneficio", 
                            placeholder: "Agregar descripcion",
                            help: "",
                            error: false },

        description_short: { value: data ? data.description_short : "", 
                             required: true, 
                             disabled: false, 
                             ref: "description_short", 
                             label: "Descripcion corta del beneficio", 
                             placeholder: "Agregar descripcion",
                             help: "",
                             error: false },
                                  
        terms_and_conditions: { value: data ? data.terms_and_conditions : "", 
                                required: false, 
                                disabled: false, 
                                ref: "terms_and_conditions", 
                                label: "Terminos y condiciones", 
                                placeholder: "Agregar terminos y condiciones",
                                help: "",
                                error: false },

    }
}
export const dataObjectBenefits = (data) => {
    return {
        
        cover_image: { value: data ? data.cover_image : "", 
                       cropped_image: "",
                       upload_route: "",
                       required: true, 
                       disabled: false, 
                       ref: "", 
                       label: "", 
                       placeholder: "",
                       help: "",
                       error: false },
                       
        title: { value: data ? data.title : "", 
                 required: true, 
                 disabled: false, 
                 ref: "title", 
                 label: "Titulo del beneficio", 
                 placeholder: "Ingresar titulo",
                 help: "",
                 error: false },
        
        description_long: { value: data ? data.description_long : "", 
                            required: true, 
                            disabled: false, 
                            ref: "description_long", 
                            label: "Descripcion del beneficio", 
                            placeholder: "Agregar descripcion",
                            help: "",
                            error: false },

        description_short: { value: data ? data.description_short : "", 
                             required: true, 
                             disabled: false, 
                             ref: "description_short", 
                             label: "Descripcion corta del beneficio", 
                             placeholder: "Agregar descripcion",
                             help: "",
                             error: false },
                                 
        terms_and_conditions: { value: data ? data.terms_and_conditions : "", 
                                required: false, 
                                disabled: false, 
                                ref: "terms_and_conditions", 
                                label: "Terminos y condiciones", 
                                placeholder: "Agregar terminos y condiciones",
                                help: "",
                                error: false },

    }
}
export const dataObjectStore = (data, settings) => {
    
    return {
        name: { value: data ? data.name : "", 
                  required: settings.name_store.required, 
                  visibility: settings.name_store.visibility, 
                  disabled: false, 
                  ref: "name", 
                  label: settings.name_store.label, 
                  placeholder: settings.name_store.placeholder,
                  help: "",
                  error: false },

        code: { value: data ? data.code : "", 
                  required: settings.code.required, 
                  visibility: settings.code.visibility, 
                  disabled: false, 
                  ref: "code", 
                  label: settings.code.label, 
                  placeholder: settings.code.placeholder,
                  help: "",
                  error: false },

        lat: { value: data ? data.lat.toString() : "", 
                  required: settings.lat.required, 
                  visibility: settings.lat.visibility, 
                  disabled: false, 
                  ref: "lat", 
                  label: settings.lat.label, 
                  placeholder: settings.lat.placeholder,
                  help: "",
                  error: false },

        lng: { value: data ? data.lng.toString() : "", 
                  required: settings.lng.required, 
                  visibility: settings.lng.visibility, 
                  disabled: false, 
                  ref: "lng", 
                  label: settings.lng.label, 
                  placeholder: settings.lng.placeholder,
                  help: "",
                  error: false },

        address: { value: data ? data.address : "", 
                  required: settings.address.required, 
                  visibility: settings.address.visibility, 
                  disabled: false, 
                  ref: "address", 
                  label: settings.address.label, 
                  placeholder: settings.address.placeholder,
                  help: "",
                  error: false },

        maximum_distance_for_checking: { value: data ? data.maximum_distance_for_checking.toString() : "", 
                  required: settings.maximum_distance_for_checking.required, 
                  visibility: settings.maximum_distance_for_checking.visibility, 
                  disabled: false, 
                  ref: "maximum_distance_for_checking", 
                  label: settings.maximum_distance_for_checking.label, 
                  placeholder: settings.maximum_distance_for_checking.placeholder,
                  help: "",
                  error: false },
            
        retailer: { value: data ? data.retailer._id ? data.retailer._id : "" : "", 
                  required: settings.retailer.required, 
                  visibility: settings.retailer.visibility, 
                  disabled: false, 
                  ref: "retailer", 
                  label: settings.retailer.label, 
                  placeholder: settings.retailer.placeholder,
                  help: "",
                  error: false },

        cluster: { value: data ? data.cluster._id ? data.cluster._id : "" : "", 
                  required: settings.cluster.required, 
                  visibility: settings.cluster.visibility, 
                  disabled: false, 
                  ref: "cluster", 
                  label: settings.cluster.label, 
                  placeholder: settings.cluster.placeholder,
                  help: "",
                  error: false },

        area: { value: data ? data.area._id ? data.area._id : "" : "", 
                  required: settings.area.required, 
                  visibility: settings.area.visibility, 
                  disabled: false, 
                  ref: "area", 
                  label: settings.area.label, 
                  placeholder: settings.area.placeholder,
                  help: "",
                  error: false },

        region: { value: data ? data.region._id ? data.region._id : "" : "", 
                  required: settings.region.required, 
                  visibility: settings.region.visibility, 
                  disabled: false, 
                  ref: "region", 
                  label: settings.region.label, 
                  placeholder: settings.region.placeholder,
                  help: "",
                  error: false },

        flag: { value: data ? data.flag._id ? data.flag._id : "" : "", 
                  required: settings.flag.required, 
                  visibility: settings.flag.visibility, 
                  disabled: false, 
                  ref: "flag", 
                  label: settings.flag.label, 
                  placeholder: settings.flag.placeholder,
                  help: "",
                  error: false },

        format: { value: data ? data.format._id ? data.format._id : "" : "", 
                  required: settings.format.required, 
                  visibility: settings.format.visibility, 
                  disabled: false, 
                  ref: "format", 
                  label: settings.format.label, 
                  placeholder: settings.format.placeholder,
                  help: "",
                  error: false },

        country: { value: data ? data.country._id ? data.country._id : "" : "", 
                  required: settings.country.required, 
                  visibility: settings.country.visibility, 
                  disabled: false, 
                  ref: "country", 
                  label: settings.country.label, 
                  placeholder: settings.country.placeholder,
                  help: "",
                  error: false },
        
    }
}

export const dataObjectUser = (data, settings, clientData = null) => {
    
    return {
        captain: { value: data ? data.captain : false, 
                  required: settings.captain.required,
                  visibility: settings.captain.visibility,
                  disabled: false, 
                  ref: "captain", 
                  label: settings.captain.label,
                  placeholder: "",
                  help: "",
                  error: false },

        avatar: { value: data ? data.avatar : "", 
                  required: settings.avatar.required,
                  visibility: settings.avatar.visibility,
                  disabled: false, 
                  ref: "avatar", 
                  label: "", 
                  placeholder: "",
                  help: "",
                  error: false },

        name: { value: data ? data.name : "", 
                required: settings.name.required,
                visibility: settings.name.visibility,
                disabled: false, 
                ref: "name", 
                label: settings.name.label,//"Nombre Completo", 
                placeholder: settings.name.placeholder,//"Nombre completo del usuario",
                help: "",
                error: false },

        last_name: { value: data ? data.last_name : "", 
                     required: settings.last_name.required, 
                     visibility: settings.last_name.visibility,
                     disabled: false, 
                     ref: "last_name", 
                     label: settings.last_name.label,//"Apellido", 
                     placeholder: settings.last_name.placeholder,//"Apellido completo del usuario",
                     help: "",
                     error: false },

        area: { value: data ? data.area?._id : "", 
                required: settings.area.required, 
                visibility: settings.area.visibility,
                disabled: false, 
                ref: "area", 
                label: settings.area.label,
                placeholder: settings.area.placeholder,
                help: "",
                error: false },
        
        birthday: { value: data ? data.birthday ? data.birthday : "" : "", 
                    required: settings.birthday.required, 
                    visibility: settings.birthday.visibility,
                    disabled: false, 
                    ref: "birthday", 
                    label: settings.birthday.label,//"Fecha de Nacimiento", 
                    placeholder: settings.birthday.placeholder,//,
                    help: "",
                    error: false },

        city: { value: data?.city ? data.city?._id : "", 
                required: settings.city.required, 
                visibility: settings.city.visibility,
                disabled: false, 
                ref: "city", 
                label: settings.city.label,//"Ciudad", 
                placeholder: settings.city.placeholder,//"",
                help: "",
                error: false },

        country: { value: data ? data.country ? data.country?._id : "" : "", 
                   required: settings.country.required, 
                   visibility: settings.country.visibility,
                   disabled: false, 
                   ref: "country", 
                   placeholder: settings.country.placeholder,//"",
                   help: "",
                   label: settings.country.label,//"País", 
                   error: false },

        email: { value: data ? data.email : "", 
                 required: settings.email.required, 
                 visibility: settings.email.visibility,
                 disabled: false, 
                 ref: "email", 
                 label: "Email", 
                 placeholder: "placeholder" in settings.email ? settings.email.placeholder : "",
                 help: "",
                 error: false },

        resend: { value: data ? data.reSend ? data.reSend : "" : "", 
                         required: false, 
                         visibility: true,
                         disabled: false, 
                         ref: "resend", 
                         label: "Re-enviar notificacion", 
                         placeholder: "", 
                         help: "Marque este campo si necesita volver a enviar el mensaje de validación o cambiar el método",
                         error: false },

        validate_drop: { value: data ? data.validation_method ? data.validation_method : "" : "", 
                         required: settings?.validate_drop?.required, 
                         visibility: settings?.validate_drop?.visibility,
                         disabled: false, 
                         ref: "validate_drop", 
                         label: settings?.validate_drop?.label, 
                         placeholder: settings?.validate_drop?.placeholder, 
                         help: "",
                         error: false },
                 
        gender: { value: data ? data.gender : "", 
                  required: settings.gender.required, 
                  visibility: settings.gender.visibility,
                  disabled: false, 
                  ref: "gender", 
                  label: settings.gender.label,//"Género", 
                  placeholder: settings.gender.placeholder,//"",
                  help: "",
                  error: false },

        language: { value: data ? data.language : "",
                    required: settings.language.required, 
                    visibility: settings.language.visibility, 
                    disabled: false, 
                    ref: "language", 
                    label: settings.language.label,//"Lengua", 
                    placeholder: settings.language.placeholder,//"",
                    help: "",
                    error: false },

        phone: { value: data ? data.phone ? data.phone : "" : "", 
                 required: settings.phone.required, 
                 visibility: settings.phone.visibility, 
                 disabled: false, 
                 ref: "phone", 
                 label: settings.phone.label,//"Teléfono", 
                 placeholder: settings.phone.placeholder,//"(011) 1147999999",
                 help: "",
                 error: false },

        prefix: { value: data ? data.prefix : "", 
                  required: settings.prefix.required, 
                  visibility: settings.prefix.visibility, 
                  disabled: false, 
                  ref: "prefix", 
                  label: settings.prefix.label,//"Prefijo", 
                  placeholder: settings.prefix.placeholder,//"",
                  help: "",
                  error: false },

        role: { value: data ? data.role?._id : "", 
                required: settings?.role.required, 
                visibility: settings?.role.visibility, 
                disabled: false, 
                ref: "role", 
                label: settings?.role.label,//"Rol", 
                placeholder: settings?.role.placeholder,//"",
                help: "",
                error: false },

        department: { value: data ? data.department_id ? data.department_id : "" : "", 
                      required: settings.department.required, 
                      visibility: settings.department.visibility, 
                      disabled: false, 
                      ref: "department", 
                      label: settings.department.label,//"Rol", 
                      placeholder: settings.department.placeholder,//"",
                      help: "",
                      error: false },

        flag: { value: data ? data.flag_id ? data.flag_id : "" : "", 
                required: settings.flag.required, 
                visibility: settings.flag.visibility, 
                disabled: false, 
                ref: "flag", 
                label: settings.flag.label,//"Rol", 
                placeholder: settings.flag.placeholder,//"",
                help: "",
                error: false },
                              
        region: { value: data ? data.region?._id : "", 
                  required: settings.region.required, 
                  visibility: settings.region.visibility, 
                  disabled: false, 
                  ref: "region", 
                  label: settings.region.label,//"Region", 
                  placeholder: settings.region.placeholder,//"",
                  help: "",
                  error: false },

        time_zone: { value: data ? data.time_zone : "",  
                     required: settings.time_zone.required, 
                     visibility: settings.time_zone.visibility, 
                     disabled: false, 
                     ref: "time_zone", 
                     label: settings.time_zone.label,//"Zona Horaria", 
                     placeholder: settings.time_zone.placeholder,//"",
                     help: "",
                     error: false },
        
        code: { value: data?.code ? data.code : "",  
                required: settings.code.required, 
                visibility: settings.code.visibility, 
                disabled: false, 
                ref: "code", 
                label: settings.code.label,
                placeholder: settings.code.placeholder,
                help: "",
                error: false },

        dni: { value: data ? data.dni : "", 
               required: settings.dni.required, 
               visibility: settings.dni.visibility, 
               disabled: false, 
               ref: "dni", 
               label: settings.dni.label,//"DNI", 
               placeholder: settings.dni.placeholder,//"Ingresar DNI",
               help: "",
               tooltip: <div className="tooltip_content">Tip: usa alguna distinción empresarial en todos los IDs (Ej: nombre o mail de la empresa), para evitar IDs ya existentes en el sistema.</div>,
               error: false },

        user_login: { value: data ? data.user_login : "",
                      required: settings.user_login.required, 
                      visibility: settings.user_login.visibility, 
                      disabled: false, 
                      ref: "user_login", 
                      label: settings.user_login.label,//"ID único", 
                      placeholder: settings.user_login.placeholder,//"Agregar identificación única...",
                      help: "",//"Letras, numeros y _, una vez cargado el usuario, es inalterable",
                      tooltip: clientData === null ? <div className="tooltip_content"><strong>Una vez creado el usuario, no puede cambiarse ni editarse.</strong><br/><br/>Tip: usa alguna <strong>distinción empresarial en todos los IDs (Ej: nombre o mail de la empresa)</strong>, para evitar IDs ya existentes en el sistema.<br/><br/><strong>Puedes usar letras, números y símbolos (@.#=).</strong></div> : <div className="tooltip_content"><strong>Una vez creado el ID de usuario, no puede editarse.</strong><br/><br/>En caso de tener un error, deberá eliminarse o notificar a su supervisor.<br/><br/>Si el usuario ya se encuentra en el sistema, se marcará en rojo con una "x"</div>,
                      //tooltip: clientData === null ? <div className="tooltip_content"><strong>Una vez creado el usuario, no puede cambiarse ni editarse.</strong><br/><br/>Tip: usa alguna <strong>distinción empresarial en todos los IDs (Ej: nombre o mail de la empresa)</strong>, para evitar IDs ya existentes en el sistema.<br/><br/><strong>Puedes usar letras, números y símbolos (@.#=).</strong></div> : <div className="tooltip_content"><strong>Una vez creado el usuario, no puede editarse.</strong><br/><br/>En caso de tener un error, deberá eliminarse o enviar un mail a <strong>contacto@somosprotagonistas.com.ar</strong><br/><br/>Si el usuario ya se encuentra en el sistema visualizarás una X</div>,
                      error: false },
    }

}



export const dataObjectCourses = (data) => {
    return {
        title: { 
            value: data ? data.title : "", 
            required: true, 
            disabled: false, 
            ref: "title", 
            label: "Titulo del curso", 
            placeholder: "Ingresar titulo",
            help: "",
            error: false 
        },
        cover_image: { 
            value: data ? data.cover_image : "", 
            cropped_image: "",
            upload_route: "",
            required: true, 
            disabled: false, 
            ref: "", 
            label: "", 
            placeholder: "",
            help: "",
            error: false 
        },
        icon: {
            value: data ? data.icon : "", 
            cropped_image: "",
            upload_route: "",
            required: false, 
            disabled: false, 
            ref: "", 
            label: "", 
            placeholder: "",
            help: "",
            error: false 
        },
        description_long: { 
            value: data ? data.description_long : "", 
            required: true, 
            disabled: false, 
            ref: "description_long", 
            label: "Descripcion del curso", 
            placeholder: "Agregar descripcion",
            help: "Máx 200 caracteres",
            error: false 
        },
        description_short: { 
            value: data ? data.description_short : "", 
            required: true, 
            disabled: false, 
            ref: "description_short", 
            label: "Descripcion corta del curso", 
            placeholder: "Agregar descripcion",
            help: "Máx 40 caracteres",
            error: false 
        },
        
    }
}


export const dataObjectModules = (data) => {
    return {
        title: { 
            value: data ? data.title : "", 
            required: true, 
            disabled: false, 
            ref: "title", 
            label: "Título del contenido", 
            placeholder: "Ingresar título...",
            help: "",
            error: false 
        },
        cover_image: { 
            value: data ? data.cover_image : "", 
            cropped_image: "",
            upload_route: "",
            required: true, 
            disabled: false, 
            ref: "", 
            label: "", 
            placeholder: "",
            help: "",
            error: false 
        },
        icon: {
            value: data ? data.icon : "", 
            cropped_image: "",
            upload_route: "",
            required: false, 
            disabled: false, 
            ref: "", 
            label: "", 
            placeholder: "",
            help: "",
            error: false 
        },
        description_long: { 
            value: data ? data.description_long : "", 
            required: true, 
            disabled: false, 
            ref: "description_long", 
            label: "Descripción del contenido", 
            placeholder: "Agregar descripción ...",
            help: "Máx 200 caracteres",
            error: false 
        },
        description_short: { 
            value: data ? data.description_short : "", 
            required: true, 
            disabled: false, 
            ref: "description_short", 
            label: "Descripción corta del contenido", 
            placeholder: "Agregar descripción ...",
            help: "Máx 40 caracteres",
            error: false 
        },
        
    }
}


export const dataObjectSurveyQuestionBasics = (data, uuid) => {
    return {
        _id: {
            value: data ? data._id : uuid, 
            required: false, 
            disabled: false, 
            ref: "_id", 
            label: "", 
            placeholder: "",
            help: null,
            error: false 
        },
        
        input_type: {
            value: data ? data.input_type ? data.input_type : "" : "", 
            required: true, 
            disabled: false, 
            ref: "input_type", 
            label: "Tipo de Pregunta", 
            placeholder: "Seleccionar tipo de pregunta",
            help: null,
            error: false 
        },
    }
}

export const dataObjectEvaluations = (data, scope = "create") => {
    return {
        points_failure: {
            value: data ? data.points_failure : "0", 
            required: false, 
            disabled: false, 
            ref: "points_failure", 
            label: "Desaprobar esta evaluacion resta", 
            placeholder: "",
            help: "",
            error: false 
        },
        points_success: {
            value: data ? data.points_success : "0", 
            required: false, 
            disabled: false, 
            ref: "points_success", 
            label: "Aprobar esta evaluacion suma", 
            placeholder: "",
            help: "",
            error: false 
        },
        type_assessment: {
            value: data ? data.type_assessment : "", 
            required: true, 
            disabled: false, 
            ref: "type_assessment", 
            label: "Estilo de evaluacion", 
            placeholder: "Seleccionar estilo de preguntas",
            help: "",
            error: false 
        },
        approval_rate: {
            value: data ? data.approval_rate : "", 
            required: true, 
            disabled: false, 
            ref: "approval_rate", 
            label: "Score minimo para aprobar evaluacion", 
            placeholder: "Seleccionar minimo",
            help: "",
            error: false 
        },

        //min_approval:{},

        count_questions: { 
            value: data ? data.count_questions : "", 
            required: scope == "create" ? true : false, 
            disabled: scope == "create" ? false : true,
            ref: "count_questions", 
            label: "Cantidad de preguntas a evaluar", 
            placeholder: "Seleccionar numero de preguntas a evaluar",
            help: "",
            error: false 
        },
        question_quantity_toload: { 
            value: data ? data.question_quantity_toload : "", 
            required: scope == "create" ? true : false, 
            disabled: scope == "create" ? false : true, 
            ref: "question_quantity_toload", 
            label: "Cantidad de preguntas a cargar", 
            placeholder: "Seleccionar numero de preguntas a cargar",
            help: "",
            error: false 
        },
        can_repeat: {
            value: data ? data.can_repeat : "", 
            required: false, 
            disabled: false, 
            ref: "can_repeat", 
            label: "Puede repetir indefinidamente", 
            placeholder: "",
            help: "",
            error: false 
        },
        can_repeat_failure: {
            value: data ? data.can_repeat_failure : "", 
            required: false, 
            disabled: false, 
            ref: "can_repeat_failure", 
            label: "Puede repetir despues de responder mal", 
            placeholder: "",
            help: "",
            error: false 
        },
        can_repeat_success: {
            value: data ? data.can_repeat_success : "", 
            required: false, 
            disabled: false, 
            ref: "can_repeat_success", 
            label: "Puede repetir despues de responder bien", 
            placeholder: "",
            help: "",
            error: false 
        },
        random: {
            value: data ? data.random : "", 
            required: false, 
            disabled: false, 
            ref: "random", 
            label: "Preguntas y opciones aleatorias", 
            placeholder: "",
            help: "",
            error: false 
        }
    }
}

export const dataObjectEvaluationsQuestionBasics = (data, uuid) => {
    return {
        _id: {
            value: data ? data._id : uuid, 
            required: false, 
            disabled: false, 
            ref: "_id", 
            label: "", 
            placeholder: "",
            help: null,
            error: false 
        },
        title: {
            value: data ? data.title ? data.title : "" : "", 
            required: true, 
            disabled: false, 
            ref: "title", 
            label: "Pregunta", 
            placeholder: "Agregar descripcion",
            help: null,
            error: false 
        },
        description: {
            value: data ? data.description ? data.description : "" : "", 
            required: false, 
            disabled: false, 
            ref: "description", 
            label: "Pregunta", 
            placeholder: "",
            help: null,
            error: false 
        },
        
        input_type: {
            value: data ? data.input_type ? data.input_type : "" : "", 
            required: true, 
            disabled: false, 
            ref: "input_type", 
            label: "Tipo de Pregunta", 
            placeholder: "Seleccionar tipo de pregunta",
            help: null,
            error: false 
        },
        penalty_score: {
            value: data ? data.penalty_score ? data.penalty_score : "0" : "0", 
            required: true, 
            disabled: false, 
            ref: "penalty_score", 
            label: "SCORE x Opc. incorrecta", 
            placeholder: "Seleccionar el score ...",
            help: null,
            error: false 
        },
        score: {
            value: data ? data.score ? data.score : "0" : "0", 
            required: true, 
            disabled: false, 
            ref: "score", 
            label: "SCORE x Opc. correcta", 
            placeholder: "Seleccionar el score ...",
            help: null,
            error: false 
        },
        
        media: { 
            value: data ? data.media ? data.media : "" : "", 
            cropped_image: "",
            upload_route: "",
            required: false, 
            disabled: false, 
            ref: "", 
            label: "", 
            placeholder: "",
            help: "",
            error: false,
            loading: false
        },
        id_module: { 
            value: data ? data.id_module ? data.id_module : "" : "", 
            required: false, 
            disabled: false, 
            ref: "id_module", 
            label: "Relaciona el modulo del curso", 
            placeholder: "",
            help: null,
            error: false 
        }
    }
}

export const dataObjectEvaluationsAnswersBasics = (data, uuid) => {
    return {
        _id: {
            value: data ? data._id : uuid, 
            required: false, 
            disabled: false, 
            ref: "_id", 
            label: "", 
            placeholder: "",
            help: null,
            error: false 
        },
        title: {
            value: data ? data.title : "", 
            required: true, 
            disabled: false, 
            ref: "title", 
            label: "Pregunta", 
            placeholder: "Agregar descripcion",
            help: null,
            error: false 
        },
        description: {
            value: data ? data.description : "", 
            required: false, 
            disabled: false, 
            ref: "description", 
            label: "Pregunta", 
            placeholder: "Agregar descripcion",
            help: null,
            error: false 
        },
        media: { 
            value: data ? data.media : "", 
            cropped_image: "",
            upload_route: "",
            required: false, 
            disabled: false, 
            ref: "", 
            label: "", 
            placeholder: "",
            help: "",
            error: false,
            loading: false
        }
    }
}

export const dataObjectSingleChoice = (data) => {
    return {
        correct: { 
            value: data ? data.correct : "", 
            required: false, 
            disabled: false, 
            ref: "correct", 
            label: "", 
            placeholder: "",
            help: null,
            error: false 
        },
        label: { 
            value: data ? data.label : "", 
            required: true, 
            disabled: false, 
            ref: "label", 
            label: "Respuesta", 
            placeholder: "Ingresar respuesta ...",
            help: "Max 100 caracteres",
            error: false 
        },
        go_to: { 
            value: data ? data.go_to : "", 
            required: true, 
            disabled: false, 
            ref: "go_to", 
            label: "Siguiente pregunta", 
            placeholder: "",
            help: null,
            error: false 
        },
    }
}


export const dataObjectMultipleChoice = (data, type) => {
    return {
        _id: { 
            value: data ? data._id : "", 
            required: false, 
            disabled: false, 
            ref: "_id", 
            label: "", 
            placeholder: "",
            help: null,
            error: false 
        },
        correct: { 
            value: data ? data.correct : "", 
            required: false, 
            disabled: false, 
            ref: "correct", 
            label: "", 
            placeholder: "",
            help: null,
            error: false 
        },
        label: { 
            value: data ? data.label : "", 
            required: true, 
            disabled: false, 
            ref: "label", 
            label: "Respuesta", 
            placeholder: "Ingresar respuesta",
            help: "Max 100 caracteres",
            error: false 
        },
        go_to: { 
            value: data ? data.go_to : "", 
            required: type == "evaluation_lineal" ? false : true, 
            disabled: false, 
            ref: "go_to", 
            label: "Siguiente pregunta", 
            placeholder: "",
            help: null,
            error: false 
        },
        media: { 
            value: data ? data.media : "", 
            cropped_image: "",
            upload_route: "",
            required: false, 
            disabled: false, 
            ref: "", 
            label: "", 
            placeholder: "",
            help: "",
            error: false ,
            loading: false
        },
        finish: { 
            value: data ? data.finish : "", 
            required: false, 
            disabled: false, 
            ref: "finish", 
            label: "Pregunta Final", 
            placeholder: "",
            help: null,
            error: false 
        }
    }
}

export const dataObjectMultipleChoiceWithImage = (data, id, type) => {
    return {
        _id: { 
            value: data ? data._id : "", 
            required: false, 
            disabled: false, 
            ref: "_id", 
            label: "", 
            placeholder: "",
            help: null,
            error: false 
        },
        id: { 
            value: data ? data.id : id, 
            required: false, 
            disabled: false, 
            ref: "id", 
            label: "", 
            placeholder: "",
            help: null,
            error: false 
        },
        correct: { 
            value: data ? data.correct : "", 
            required: false, 
            disabled: false, 
            ref: "correct", 
            label: "Respuesta", 
            placeholder: "",
            help: null,
            error: false 
        },
        finish: { 
            value: data ? data.finish : "", 
            required: false, 
            disabled: false, 
            ref: "finish", 
            label: "Pregunta Final", 
            placeholder: "",
            help: null,
            error: false 
        },
        go_to: { 
            value: data ? data.go_to : "", 
            required: type == "evaluation_lineal" ? false : true, 
            disabled: false, 
            ref: "go_to", 
            label: "Siguiente pregunta", 
            placeholder: "",
            help: null,
            error: false 
        },
        media: { 
            value: data ? data.media : "", 
            cropped_image: "",
            upload_route: "",
            required: true, 
            disabled: false, 
            ref: "", 
            label: "", 
            placeholder: "",
            help: "",
            error: false ,
            loading: false
        },
    }
}

export const dataObjectOpenAnswer = (data) => {
    return {
        _id: { 
            value: data ? data._id : "", 
            required: false, 
            disabled: false, 
            ref: "_id", 
            label: "", 
            placeholder: "",
            help: null,
            error: false 
        },
        id: { 
            value: data ? data.id : "", 
            required: false, 
            disabled: false, 
            ref: "id", 
            label: "", 
            placeholder: "",
            help: null,
            error: false 
        },
        correct: { 
            value: data ? data.correct : "", 
            required: false, 
            disabled: false, 
            ref: "correct", 
            label: "Respuesta", 
            placeholder: "",
            help: null,
            error: false 
        },
        label: { 
            value: data ? data.label : "", 
            required: false, 
            disabled: false, 
            ref: "label", 
            label: "Siguiente pregunta", 
            placeholder: "",
            help: null,
            error: false 
        }
    }
}

export const dataObjectPosts = (data) => {
    return {
        title: { 
            value: data ? data.title : "", 
            required: true, 
            disabled: false, 
            ref: "title", 
            label: "Titulo", 
            placeholder: "Agregar titulo",
            help: "Max 100 caracteres",
            error: false 
        },
        description_long: { 
            value: data ? data.description_long : "", 
            required: false, 
            disabled: false, 
            ref: "description_long", 
            label: "Descripcion", 
            placeholder: "Agregar descripcion",
            help: "Máx 250 caracteres",
            error: false 
        },
        description_short: { 
            value: data ? data.description_short : "", 
            required: false, 
            disabled: false, 
            ref: "description_short", 
            label: "Descripcion corta del post", 
            placeholder: "Agregar descripcion",
            help: "Máx 40 caracteres",
            error: false 
        }
    }
}

export const dataObjectProduct = (data) => {
    return {
        name: { 
            value: data ? data.name : "", 
            required: false, 
            disabled: false, 
            ref: "name", 
            label: "Titulo del Producto", 
            placeholder: "Agregar titulo",
            help: "",
            error: false 
        },
        description_short: { 
            value: data ? data.description_short : "", 
            required: false, 
            disabled: false, 
            ref: "description_short",   
            label: "Descripcion", 
            placeholder: "Agregar descripcion del producto",
            help: "",
            error: false 
        }
    }
}

export const dataObjectSurvey = (data) => {
    return {
        _id: { 
            value: data ? data._id : "", 
            required: false, 
            disabled: false
        },
        name: { 
            value: data ? data.name : "", 
            required: false, 
            disabled: false, 
            ref: "name", 
            label: "Titulo", 
            placeholder: "Agregar titulo",
            help: "",
            error: false 
        },
        description: { 
            value: data ? data.description : "", 
            required: false, 
            disabled: false, 
            ref: "description",   
            label: "Descripcion", 
            placeholder: "Agregar descripcion de la encuesta",
            help: "",
            error: false 
        }
        /*data_init: { 
            value: data ? data.data_init : "", 
            required: false, 
            disabled: false, 
            ref: "data_init",   
            label: "", 
            placeholder: ".",
            help: "",
            error: false 
        },
        data_finish: { 
            value: data ? data.data_finish : "", 
            required: false, 
            disabled: false, 
            ref: "data_init",   
            label: "", 
            placeholder: "",
            help: "",
            error: false 
        }*/
    }
}
