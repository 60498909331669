import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';
import { path } from 'ramda';
import Dialog from '@mui/material/Dialog';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import InputCustom from '../InputCustom';
import SelectCustom from '../../../components/elements/SelectCustom';
import ButtonCustom from '../../../components/elements/ButtonCustom';
import ButtonTextCustom from '../../../components/elements/ButtonTextCustom';
import { HiMagnifyingGlass } from "react-icons/hi2";

import LoaderComponent from '../../../components/elements/LoaderComponent';
import { replaceSpecialCharacters } from '../../../helpers/functions';

import { ContentContext,
         ConfigurationContext } from '../../../context';

const DialogStyled = styled(Dialog)`
    .MuiDialog-paper{
        border-radius: 8px;
    }
    .actions{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        width: 100%;
        .bt_submit{

        }
        .bt_cancel{
            margin-left: 10px;
        }
    }
    .loader{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-content: center;
        width: 100%;
    }
    .inner_dialog{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 1050px;
        //min-height: 700px;
        box-sizing: border-box;
        padding: 35px 30px 35px 30px;
        .noresult{
            width: 100%;
            text-align: center;
            margin-top: 15px;
            font-weight: 600;
            font-size: 14px;
            line-height: 14px;
        }
        .header_dialog{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 100%;
            .icon{
                width: 90px;
                svg{
                    float: left;
                    width: 100%;
                }
            }
            .title{
                font-weight: 600;
                font-size: 25px;
                line-height: 35px;
                text-align: center;
                .bolder{
                    font-weight: 600;
                }
            }
            .subtitle{
                margin: 10px 0 0 0;
                font-size: 18px;
                line-height: 22px;
                color: ${props => `rgb(${props.pancolors.dark_gray})`};
            }
        }
        .content_dialog{
            display: flex;
            width: 100%;
            margin-top: 20px;
            .field{
                width: 100%;
                .datetime_activate_at{
                    width: 100%;
                }
            }
        }
        .actions_dialog{
            display: flex;
            flex-direction: column;
            width: 100%;
            >.bt_submit{
                margin-top: 20px;
            }
            >.bt_cancel{
                margin-top: 10px;
            }
        }
        .label_no_segmentation{
            margin-bottom: 0;
        }
        .add_aditional_information{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            margin-bottom: 75px;
            margin-top: 15px;
            width: 100%;
            .bt_add_more_info{
                display: flex;
                flex-direction: row;
                align-self: flex-start;
                padding: 0;
                margin-bottom: 5px;
            }
            .aditional_information_el{
                justify-content: space-between!important;
                margin-bottom: 10px;
                width: 100%;
            }
            .actions{
                width: 70px!important;
            }
            .fields{
                width: calc(100% - 130px)!important;
            }
            .no_product_error{
                color: red;
            }
        }
        
        .actions{
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            margin-top: 30px;
            .bt_submit{

            }
            .bt_program_activation{
                margin-left: 10px;
            }
            .bt_cancel{
                margin-left: 10px;
            }
        }
        .box_options{
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            width: 100%;
            margin-top: 15px;
            .field_keywords{
                width: 100%;
            }
            >.filter_actions{
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;
                margin-top: 5px;
                margin-bottom: 20px;
                .field_keywords{
                    width: 100%;
                }
                .filter_option{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-start;
                    width: 100%;
                    margin-top: 10px;
                    .field_region{
                        width: 215px;
                        margin-left: 15px;
                    }
                    .field_status{
                        width: 150px;
                        margin-left: 15px;
                    }
                    .field_area{
                        width: 180px;
                        margin-left: 10px;
                    }
                    .field_role{
                        width: 180px;
                        margin-left: 10px;
                    }
                    .field_clusters{
                        width: 330px;
                        margin-left: 10px;
                    }
                    .label{
                        font-size: 15px;
                        white-space: nowrap;
                    }
                    .bt_filter{
                        margin-left: 10px;
                    }
                    .profile{
                        margin-left: 10px;
                        select{
                            width: 150px;
                        }
                    }
                    .status{
                        select{
                            width: 150px;
                        }
                    }
                }
            }
        }

        .box_result{
            border: 1px dashed rgb(223 226 237);
            padding: 10px;
            border-radius: 12px;
            width: 100%;
            box-sizing: border-box;
            max-height: 280px;
            overflow: hidden;
            overflow-y: auto;
            &.hide{
                display: none;
            }
            &.show{
                display: flex;
            }
            .tags{
                width: 100%;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                .tag{
                    border: 1px solid rgb(223 226 237);
                    font-size: 13px;
                    line-height: 13px;
                    padding: 10px 10px;
                    border-radius: 6px;
                    color: rgb(108, 108, 153);
                    white-space: nowrap;
                    margin: 3px;
                    cursor: pointer;
                    &.selectd{
                        color: rgba(109, 105, 239, 1);
                        border: 1px solid rgb(109, 105, 239);
                        background-color: rgba(109, 105, 239, 0.15);
                    }
                    :hover{
                        color: rgba(109, 105, 239, 1);
                        border: 1px solid rgb(109, 105, 239);
                        background-color: rgba(109, 105, 239, 0.15);
                    }
                }
            }
        }
        .box_selection{
            border: 1px solid rgb(223 226 237);
            padding: 10px;
            border-radius: 12px;
            width: 100%;
            box-sizing: border-box;
            max-height: 280px;
            overflow: hidden;
            overflow-y: auto;
            &.hide{
                display: none;
            }
            &.show{
                display: flex;
            }
            .tags{
                width: 100%;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                .tag{
                    border: 1px solid rgb(57 53 183);
                    color: #FFFFFF;
                    border: 1px solid rgb(109, 105, 239);
                    background-color: rgba(109, 105, 239, 1);

                    font-size: 13px;
                    line-height: 13px;
                    font-weight: 600;
                    padding: 10px 10px;
                    border-radius: 6px;
                    white-space: nowrap;
                    margin: 3px;
                    cursor: pointer;

                    :hover{
                        color: red;
                        border: 1px solid red;
                        background-color: rgba(255, 0, 0, 0.05);
                    }
                }
            }
        }
        .divider{
            width: 100%;
            height: 1px;
            background-color: rgb(229 231 237);
            margin-top: 20px;
            margin-bottom: 20px;
        }

    }
`

const Products = (props) => {
    
    const [ isLoading, setIsLoading] = useState(false);
    const [ resultSelected, setResultSelected ] = useState([]);
    const [ selection, setSelection ] = useState([]);

    const [ status, setStatus ] = useState("1");
    const [ profile, setProfile ] = useState(null);
    const [ area, setArea ] = useState(null);
    const [ role, setRole ] = useState(null);
    const [ region, setRegion ] = useState(null);
    const [ profileList, setProfileList ] = useState([]);

    const [ areas, setAreas ] = useState([]);
    const [ roles, setRoles ] = useState([]);
    const [ regions, setRegions ] = useState([]);
    

    const { getUsers,
            basicData } = useContext(ContentContext);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const { panColors,
            clientData } = useContext(ConfigurationContext);

    const { open, 
            setOpen,
            segmentationUsersList,
            setSegmentationUsersList } = props;
    
    const statusList = [{_id: "0", name: "Activos"}, {_id: "-2", name: clientData.name_clients === "one_sp" ? "Licenciados" : "Inactivos"}];
    const [ filters, setFilters ] = useState(null);
    const [ results, setResults ] = useState(null);

    const formStyle = {background_color: `rgba(${panColors.white}, 1)`,
                       border_color: `rgba(${panColors.middle_gray}, 1)`};
    
    const [ keywords, setKeywords ] = useState({ value: "", 
                                                 required: false, 
                                                 disabled: false, 
                                                 ref: "keywords", 
                                                 label: "Palabra / Frase Clave", 
                                                 placeholder: "Buscar....",
                                                 help: "",
                                                 error: false})
    const statusRef = React.useRef(status);
    const profileRef = React.useRef(profile);
    const areaRef = React.useRef(area); 
    const roleRef = React.useRef(role); 
    const regionRef = React.useRef(region);
    const keywordsRef = React.useRef(keywords);

    const handleCancel = () => {

        const keywords_update = {...keywords};
        keywords_update.value = "";
        setKeywords(keywords_update);

        keywordsRef.current = "";
        setResultSelected([]);
        setSelection([]);
        setRegions("");
        setStatus("1");
        setProfile(null);
        setFilters(null);
        setResults(null);
        setArea(null);
        setRole(null);
        setProfileList([]);
        setIsLoading(false);
        setOpen(false);
    };

    const handleSubmit = () => {
        
        setSegmentationUsersList(selection);
        handleCancel();

    }

    const handleFormElementChange = (name, value) => {

        switch (name) {
            case "status":
                setStatus(value);
                statusRef.current = value;
                break;
            case "profile":
                setProfile(value);
                profileRef.current = value;
                break;
            case "area":
                setArea(value);
                areaRef.current = value;
                break;
            case "role":
                setRole(value);
                roleRef.current = value;
                break;
            case "region":
                setRegion(value);
                regionRef.current = value;
                break;
            case "keywords":
                let updateKeywords = {...keywords};
                updateKeywords.value = value;
                setKeywords(updateKeywords);
                keywordsRef.current = value;
                break;
            default:
                break;
        }
    }

    const handleKeyDown = (key) => {
        if(key === "Enter"){
            handleFilter();
        }
    }

    const handleFilter = (e) => {
        setIsLoading(true);
        const update_filters = {...filters};
        update_filters.alphabetical_order = "1";
        update_filters.date_order = "1";
        update_filters.area = area ? area : "";
        update_filters.role = role ? role : "";
        update_filters.region = region ? region : "";
        update_filters.status = status;
        update_filters.text_filter = replaceSpecialCharacters(keywords.value);
        setFilters(update_filters);
    }

    const isSelected = (_id) => {
        const is_selected = resultSelected.filter(r => r._id === _id);
        return is_selected.length ? true : false;
    }

    const handleSelectElementResult = (el) => {

        const addElement = resultSelected.filter(r => r._id === el._id);
        if(!addElement.length){
            let resultSelectedUpdate = [...resultSelected];
            resultSelectedUpdate.push({_id: el._id, name: `${el.name} ${el.last_name}`});
            setResultSelected(resultSelectedUpdate);
        }else{
            const removeElement = resultSelected.filter(r => r._id !== el._id);
            setResultSelected(removeElement);
        }

        const addSelection = selection.filter(s => s._id === el._id);
        if(!addSelection.length){
            let selectiondUpdate = [...selection];
            selectiondUpdate.push({_id: el._id, name: `${el.name} ${el.last_name}`});
            setSelection(selectiondUpdate);
        }else{
            const removeSelection = selection.filter(s => s._id !== el._id);
            setSelection(removeSelection);
        }

    }

    const handleSelectedElement = (_id) => {

        const removeSelection = selection.filter(s => s._id !== _id);
        setSelection(removeSelection);

        const removeElement = resultSelected.filter(r => r._id !== _id);
        setResultSelected(removeElement);

    }
    
    useEffect(() => {
        
        if(filters){
            const response = getUsers(0, 1000000, filters, true);
            response.then(res =>{
                setIsLoading(false);
                setResults(path(['data', 'single', 'users'], res));
            })
        }

    }, [filters])

    useEffect(() => {
        if(basicData && basicData.user_profiles){
            const arr_user_profiles = [];
            basicData.user_profiles.map((profile, index) => {
                arr_user_profiles.push({_id: profile._id, name: profile.name});
            })
            setProfile(arr_user_profiles[0].value);
            setProfileList(arr_user_profiles);
        }
        if(basicData){
            setAreas(basicData.areas);


            let role_list = basicData.roles;
            if(clientData.name_clients === 'one_sp'){
                if(clientData.user_role.code !== '8'){
                    const roles_updates = {...basicData};
                    role_list = roles_updates.roles.filter(rol => rol._id !== "6494b12090c83a00089bde56" && rol._id !== "6494c5eaf8cf9900084250dd" && rol._id !== "6494c715f8cf9900084251c5" && rol._id !== "6494c299b63e0a0008de6617");
                }
            }
        
            

            setRoles(role_list);
            setRegions(basicData.regions);
        }

    }, [basicData])
    

    
    useEffect(() => {
        if(open){
            if(segmentationUsersList?.length){
                const selectiondUpdate = segmentationUsersList.map(user => {
                    return {_id: user?._id, name: user?.name};
                })
                setSelection(selectiondUpdate);
                setResultSelected(selectiondUpdate);
            }
        }
      return () => {}
    }, [open])
    

    return (
        <DialogStyled
            fullScreen={fullScreen}
            open={open}
            onClose={handleCancel}
            aria-labelledby="responsive-dialog-title"
            maxWidth="xl"
            pancolors={panColors}
        >
            <div className="inner_dialog">

                <div className='header_dialog'>
                    <div className='title'>Segmentación del Beneficio</div>
                    <div className='subtitle'>Usuarios</div> 
                </div>
                
                <div className='box_options'>
                    <div className="filter_actions">

                        <InputCustom 
                            name={keywords.ref}
                            label={""}
                            placeholder={keywords?.placeholder}
                            onChange={handleFormElementChange}
                            onKeyDown={handleKeyDown}
                            onKeyUp={null}
                            className={`field field_${keywords.ref}${keywords.error ? " error" : ""}`}
                            type="text"
                            value={keywords.value}
                            help={keywords.help}
                            isRequired={keywords.required}
                            styles={
                                {background_color: `rgba(${panColors.light_white}, 1)`,
                                border_color: `rgba(${panColors.light_white}, 1)`}
                            }
                            icon={<HiMagnifyingGlass />}
                            tooltip_content={keywords.tooltip}
                            haveError={keywords.error}
                        />

                        <div className="filter_option">
                            <div className="label_title">
                                Filtrar por:
                            </div>

                            {((basicData.settings.users.section_list_filters.filter(filter => filter.id === 'status')).length) ? 
                            <SelectCustom 
                                name={"status"}
                                label={""}
                                placeholder={null}
                                onChange={handleFormElementChange}
                                className={`field field_status`}
                                type="text"
                                value={status}
                                help={null}
                                isRequired={null}
                                styles={ formStyle }
                                label_icon={null}
                                tooltip_content={null}
                                haveError={false}
                                data={statusList}
                            /> : ""} 

                            {((basicData.settings.users.section_list_filters.filter(filter => filter.id === 'area')).length) ?
                            <SelectCustom 
                                name={"area"}
                                label={""}
                                placeholder={`${(basicData.settings.users.section_list_filters.filter(filter => filter.id === 'area'))[0].label} ...`}
                                onChange={handleFormElementChange}
                                className={`field field_area`}
                                type="text"
                                value={area}
                                help={null}
                                isRequired={null}
                                styles={ formStyle }
                                label_icon={null}
                                tooltip_content={null}
                                haveError={false}
                                data={areas}
                            /> : ""}

                            {((basicData.settings.users.section_list_filters.filter(filter => filter.id === 'role')).length) ?
                            <SelectCustom 
                                name={"role"}
                                label={""}
                                placeholder={"Rol ..."}
                                onChange={handleFormElementChange}
                                className={`field field_role`}
                                type="text"
                                value={role}
                                help={null}
                                isRequired={null}
                                styles={ formStyle }
                                label_icon={null}
                                tooltip_content={null}
                                haveError={false}
                                data={roles}
                            /> : ""}

                            {((basicData.settings.users.section_list_filters.filter(filter => filter.id === 'region')).length) ? 
                            <SelectCustom 
                                name={"region"}
                                label={""}
                                placeholder={"Región ..."}
                                onChange={handleFormElementChange}
                                className={`field field_region`}
                                type="text"
                                value={region}
                                help={null}
                                isRequired={null}
                                styles={ formStyle }
                                label_icon={null}
                                tooltip_content={null}
                                haveError={false}
                                data={regions}
                            />  : ""}

                            <ButtonCustom 
                                className="bt_filter"
                                text="Filtrar"
                                onClick={handleFilter}
                                style_type="normal"

                                icon={null}
                                background={{ normal: panColors.primary, hover: panColors.white }}
                                border={{ normal: panColors.primary, hover: panColors.primary }}
                                textcolor={{ normal: panColors.white, hover: panColors.primary }}
                            />

                        </div>


                    </div>
                    
                </div>

                {isLoading ?
                <div className='loader'>
                    <LoaderComponent size={20}  />
                </div> 
                : 
                <>
                {results !== null && !results.length ?
                    <div className='noresult'>No hay resultados para mostrar</div>
                : ""}

                <div className={`box_result${results?.length ? " show" : " hide"}`}>
                    
                        <>
                            {results === null ?
                            <></>
                            :
                            results.length ?
                                <div className='tags'>
                                    {results.map((result, index) => {
                                        return(
                                            <div className={`tag${isSelected(result._id) ? " selectd" : ""}`} onClick={() => handleSelectElementResult(result)}>
                                                {`${result.name} ${result.last_name}`}
                                            </div>
                                        )
                                    })}
                                </div>
                                :
                                <>no hay resultados</>
                            }
                        </>
                    
                </div>
                </>
                }

                <div className='divider'></div>

                <div className={`box_selection${selection.length ? " show" : " hide"}`}>
                    {selection === null ?
                    <></>
                    :
                    selection.length ?
                        <div className='tags'>
                            {selection.map((s, index) => {
                                return(
                                    <div className={`tag`} onClick={() => handleSelectedElement(s._id)}>
                                        {s.name}
                                    </div>
                                )
                            })}
                        </div>
                        :
                        <>no hay resultados</>
                    }
                </div>

                <div className="actions">
                    <ButtonCustom 
                        className="bt_submit"
                        text={"Guardar"}
                        onClick={handleSubmit}
                        style_type="normal"
                        
                        icon={null}
                        background={{ normal: panColors.primary, hover: panColors.white }}
                        border={{ normal: panColors.primary, hover: panColors.primary }}
                        textcolor={{ normal: panColors.white, hover: panColors.primary }}
                    />
                    <ButtonTextCustom 
                        className="bt_cancel"
                        text="Cancelar"
                        onClick={() => handleCancel()}

                        icon={null}
                        background={{ normal: "", hover: "" }}
                        border={{ normal: "", hover: "" }}
                        textcolor={{ normal: panColors.red, hover: panColors.red }}
                    />
                </div>

            </div>
        </DialogStyled>
    );
}

export default Products;